$(document).ready(function () {
  $(function () {
    $('.lazy').Lazy({
      effect: 'fadeIn',
      visibleOnly: true,
      onError: function (element) {
        console.log('error loading ' + element.data('src'));
      },
    });
  });

  $(window).scroll(function () {
    let sticky = $('.header'),
      scroll = $(window).scrollTop();

    if (scroll >= 40) {
      sticky.addClass('active');
    } else {
      sticky.removeClass('active');
    }
    if (scroll > 180) {
      $('.btn-top').fadeIn();
    } else {
      $('.btn-top').fadeOut();
    }
  });

  $('.btn-top').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 800);
    return false;
  });

  $('.hamburger').on('click', () => {
    $('.hamburger').toggleClass('active');
    $('.header_content').toggleClass('active');
    $('.lang').toggleClass('active');
    $('.ticket').toggleClass('active');
    $('body').hasClass('hidden') ? $('body').removeClass('hidden') : $('body').addClass('hidden');
  });

  let a = 0;
  $(window).scroll(function () {
    if ($('*').hasClass('bg-change')) {
      let oTop = $('.basic_content').offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        $('.counter').each(function () {
          let $this = $(this),
            countTo = $this.attr('data-number');
          $({ countNum: $this.text() }).animate(
            {
              countNum: countTo,
            },
            {
              duration: 850,
              easing: 'swing',
              step: function () {
                //$this.text(Math.ceil(this.countNum));
                $this.text(Math.ceil(this.countNum).toLocaleString('ru'));
              },
              complete: function () {
                $this.text(Math.ceil(this.countNum).toLocaleString('ru'));
                //alert('finished');
              },
            },
          );
        });
        a = 1;
      }
    }
  });

  setTimeout(() => {
    $('.modal').addClass('active');
  }, 2000);

  $('.modal .close').on('click', () => {
    $('.modal').removeClass('active');
  });

  $('.gallery-photos a').simpleLightbox();
  $('.info-slide .swiper-slide a').simpleLightbox();
  $('.block__grid__item a').simpleLightbox();

  $('.desc-slice').text($('.desc-slice').text().slice(0, 131) + '...');

  // language select style
  $('.lang select ').change(function () {
    if ($(this).val()) {
      $('.lang select ').css({ width: '100px' });
      $('.lang select option ').css({ textAlign: 'end' });
    }
  });
});

// slider for mountine
const swiperHead = new Swiper('.swiper-head', {
  // Optional parameters
  loop: true,
  // If we need pagination
  pagination: {
    el: '.swiper-head .swiper-pagination',
    type: 'fraction',
    clickable: true,
    bulletActiveClass: 'bullet_active',
  },
  autoplay: {
    delay: 5000,
  },
});

// slider for expo
const swiperMiddle = new Swiper('.swiper-middle', {
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },
  autoplay: {
    delay: 3000,
  },
});

// slider for monument
const swiperMonument = new Swiper('.monument', {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },
  autoplay: {
    delay: 3000,
  },
});

const infoSlide = new Swiper('.info-slide', {
  spaceBetween: 15,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
    delay: 4000,
  },
  breakpoints: {
    768: {
      slidesPerView: 2.6,
      spaceBetween: 20,
    },
    414: {
      slidesPerView: 1.6,
    },
    80: {
      slidesPerView: 1.6,
    },
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: true,
  },
});

// tabs
const newsItems = document.querySelectorAll('.news-item');
const newsItemsContent = document.querySelectorAll('.news-content__item');
const newsItemsContentText = document.querySelectorAll('.news-content__item__text');

newsItems.forEach((news, id) => {
  news.addEventListener('click', () => {
    newsItems.forEach((i) => i.classList.remove('active'));
    newsItems[id].classList.add('active');
    newsItemsContentText[id].classList.add('active');
    newsItemsContent.forEach((i) => i.classList.remove('active'));
    newsItemsContentText[id].classList.remove('active');
    newsItemsContent[id].classList.add('active');
  });
});

const textGalleries = document.querySelectorAll('.text-gallery');
const textNewsItems = document.querySelectorAll('.news-content__item__text p');

textGalleries.forEach((text) => {
  text.textContent = text.textContent.slice(0, 50) + '...';
});

textNewsItems.forEach((text) => {
  text.textContent = text.textContent.slice(0, 250) + '...';
});
